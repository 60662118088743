import { json, LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
    isRouteErrorResponse,
    Link,
    Links,
    LiveReload,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useRouteError,
} from '@remix-run/react';
import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
//import toastify from 'react-toastify/dist/ReactToastify.css';
import styles from '@assets/css/common.css?url';
import 'react-toastify/dist/ReactToastify.css';
import ModalContainer from '@components/modal/ModalContainer';
import { useToast } from '@hooks/useToast';
import { getToast } from '@server/toast.server';
import { combineHeaders } from '@server/utils/common';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export async function loader({ request }: LoaderFunctionArgs) {
    const { toast, headers: toastHeaders } = await getToast(request);

    return json(
        {
            toast,
        },
        {
            headers: combineHeaders(toastHeaders),
        },
    );
}

export function Layout({ children }: { children: ReactNode }) {
    return (
        <html lang="ko">
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <Meta />
                <Links />
            </head>
            <body>
                {children}

                <ScrollRestoration />
                <Scripts />
                <LiveReload />
            </body>
        </html>
    );
}

export default function App() {
    const data = useLoaderData<typeof loader>();
    useToast(data.toast);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Outlet />
            <ModalContainer />
        </>
    );
}

export function ErrorBoundary() {
    const error = useRouteError();
    return (
        <div className="error_con">
            <div className="error_in">
                <p className="error_tit">페이지를 찾을 수 없습니다.</p>
                <p className="error_copy">
                    요청하신 페이지의 주소가 잘못 입력되었거나,
                    <br />
                    주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
                </p>

                <div className="error_btn_con">
                    <Link to="/" className="com_btn error">
                        돌아가기
                    </Link>
                </div>
            </div>
        </div>
    );
}
